import React, {useState, useEffect} from 'react'
import lib from './lib'
import imageHero from './images/hero.png'

const App = props => {
    const [votes, setVotes] = useState(null);
    const getVotes = async () => {
        const {votes} = await lib.getVotes();
        setVotes(votes || 0);
    };

    const saveVote = async (e) => {
        e.preventDefault();
        setVotes(votes + 1);
        await lib.saveVote();
    };

    useEffect(() => {
        (async () => {
            await getVotes();
        })()
    }, []);

    return (
        <div className="container">
            <div className="hero">
                <img src={imageHero}/>
            </div>

            <div className="tagline">
                a fullstack app built on serverless components via the serverless framework
            </div>

            <div className="buttonContainer">
                <div
                    className={`button`}
                    onClick={saveVote}
                >
                    <div className={`buttonInner`}>
                        <div className={`buttonLeft`}>ß</div>
                        <div className="buttonRight">{votes}</div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default App;